import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const BookingMain = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const { bookingNumber } = data;
    navigate(`/booking-details/${bookingNumber}`);
  };

  return (
    <div className="container mx-auto px-4 py-16 min-h-[900px]">
      <h1 className="text-center text-2xl flex flex-col items-center mt-32">
        BOOKING
        <span className="relative mt-2 w-full max-w-[200px]">
          <span className="block bg-gray-300 w-full h-[1px]"></span>
          <span className="absolute left-1/2 transform -translate-x-1/2 bg-red-500 w-[50px] h-[4px] -top-[2px]"></span>
        </span>
      </h1>
      <p className="text-center text-gray-600 mt-4 mb-14">
        Search for your booking details by entering your unique Booking Number
        in the field below.
      </p>

      {/* Form */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0">
          <input
            {...register('bookingNumber', {
              required: 'Booking Number is required',
            })}
            className="p-3 w-[288px] sm:min-w-[320px] h-full rounded-md text-black text-center border border-gray-300"
            type="text"
            placeholder="BOOKING NUMBER"
            style={{
              fontFamily: 'sans-serif',
            }}
          />
        </div>
        {errors.bookingNumber && (
          <p className="text-red-500 text-md pt-2 text-center">
            {errors.bookingNumber.message}
          </p>
        )}
        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="bg_color_new text-white rounded-md font-medium min-w-[288px] sm:min-w-[320px] h-full px-4 py-2"
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default BookingMain;
