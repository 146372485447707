import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { TbLogout } from 'react-icons/tb';

import { MdOutlineCancel } from 'react-icons/md';
import Cookies from 'js-cookie';
import { useStateContext } from '../contexts/ContextProvider';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/logo-dark.png';
import { LogoutOutlined } from '@ant-design/icons';
import { TbCategory } from 'react-icons/tb';
import { BiCheckCircle, BiSolidShip } from 'react-icons/bi';

const AdminSidebar = () => {
  const {
    activeMenu,
    setActiveMenu,
    screenSize,
    productsOpen,
    isClicked,
    handleClick,
    setIsClicked,
  } = useStateContext();
  const { user } = useSelector((state) => ({ ...state }));
  const location = useLocation();
  const dispatch = useDispatch();
  const [product, setProduct] = useState(false);
  const [till, setTill] = useState(false);
  const [returns, setReturns] = useState(false);

  const navigation = useNavigate();

  const links = [
    {
      id: 1,
      path: '/admin/categories',
      name: 'Category',
      icon: <TbCategory />,
    },
    {
      id: 2,
      path: '/admin/shipping/manage-bl',
      name: 'Shipping',
      icon: <BiSolidShip />,
    },
    {
      id: 3,
      path: '/admin/booking/manage-bookings',
      name: 'Booking',
      icon: <BiCheckCircle />,
    },
  ];

  const logout = () => {
    Cookies.remove('user');
    dispatch({
      type: 'LOGOUT',
      payload: null,
    });
    navigation('/login');
  };

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    'flex items-center gap-2 pl-3 pt-3 pb-2.5 text-white text-md';
  const normalLink =
    'flex items-center gap-2 pl-4 pt-3 pb-2.5 text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray';

  return (
    <div className="w-56 sm:w-full md:w-full lg:w-full h-full md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => setActiveMenu(!activeMenu)}
              className="text-xl rounded-full p-3 mt-4 block md:hidden"
            >
              <MdOutlineCancel
                style={{
                  color: 'blue',
                }}
              />
            </button>
          </div>
          <div className="mt-0">
            {links.map((link) => {
              return (
                <NavLink
                  to={`${link.path}`}
                  key={link.id}
                  onClick={handleCloseSideBar}
                  className={({ isActive }) =>
                    isActive
                      ? `${activeLink} link_dash_act`
                      : `${normalLink} link_dash`
                  }
                  style={({ isActive }) => ({
                    backgroundColor: isActive ? '#0e86d4' : '',
                    paddingLeft: 15,
                  })}
                >
                  <span className="text-white">{link.icon}</span>
                  <span className="capitalize text-white">{link.name}</span>
                </NavLink>
              );
            })}

            <NavLink
              to="/"
              onClick={logout}
              className={`${normalLink} link_dash`}
            >
              <LogoutOutlined
                style={{
                  color: '#fff',
                }}
              />
              <span className="capitalize text-white">Logout</span>
            </NavLink>

            <div className="mt-10 flex justify-center items-center">
              <img
                src={logo}
                alt="branding logo"
                className="mb-2"
                style={{
                  width: '120px',
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminSidebar;
