import axios from 'axios';

export const addBookingFront = async (newdata, authtoken, setErrorData) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}create-booking`,
      newdata,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    return { status: 'ok', data };
  } catch (error) {
    setErrorData(error.response.data.message);
    return error.response.data.message;
  }
};

export const getAllBookingsFront = async (authtoken) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}get-all-booking`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response?.data?.message || 'Error fetching bookings';
  }
};

export const getBookingDetailsByIdFront = async (id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}get-booking-by-id/${id}`
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const getBookingDetailsByBookingIdFront = async (id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}get-booking-track-by-id/${id}`
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateBookingFront = async (id, newData, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}update-booking-by-id/${id}`,
      newData,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteBookingFront = async (id, authtoken) => {
  try {
    axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}delete-booking-by-id/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    return { status: 'ok' };
  } catch (error) {
    return error.response.data.message;
  }
};
