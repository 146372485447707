import React from 'react';
import { blogData } from '../data';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Blog = () => {
  return (
    <div className="container mx-auto px-4 py-16 min-h-[900px]">
      <h1 className="text-center text-2xl flex flex-col items-center">
        OUR BLOG
        <span className="relative mt-2 w-full max-w-[200px]">
          <span className="block bg-gray-300 w-full h-[1px]"></span>
          <span className="absolute left-1/2 transform -translate-x-1/2 bg-red-500 w-[50px] h-[4px] -top-[2px]"></span>
        </span>
      </h1>
      <p className="text-center text-gray-600 mt-4 mb-14">
        Our experts offer their expertise to find the most suitable solution
        with minimal hassle, regardless of your company's size or industry.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {blogData.map((blog) => (
          <div
            key={blog.id}
            className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col"
          >
            <img
              src={blog.image}
              alt={blog.title}
              className="w-full h-64 object-cover"
            />
            <div className="p-4 flex-grow">
              <h3 className="text-xl font-bold">{blog.title}</h3>
              <p className="text-sm text-gray-500">
                {moment(blog.date).format('ddd, DD MMM YY')}
              </p>
              <p className="mt-2 text-gray-700">
                {blog.description[0].slice(0, 100)}...
              </p>
            </div>
            <div className="p-4 mt-auto">
              <Link
                to={`/blog/${blog.id}`}
                className="text-red-500 hover:underline font-bold"
              >
                Read more
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
