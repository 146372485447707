/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  CheckCircleFilled,
  CloudDownloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import BarLoader from 'react-spinners/BarLoader';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import logo from '../assets/logo.png';
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import jsPDF from 'jspdf';
import { LiaShipSolid } from 'react-icons/lia';
import Steps from 'rsuite/Steps';
import 'jspdf-autotable';
import { getBookingDetailsByBookingIdFront } from '../components/functions/booking';

const BookingDetails = () => {
  const { bookingNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [bookingData, setBookingData] = useState(null);

  useEffect(() => {
    document.title = `Booking - ${bookingNumber}`;
    loadBookingDetails();
  }, [bookingNumber]);

  const loadBookingDetails = () => {
    setLoading(true);
    setError('');
    setSuccess('');
    getBookingDetailsByBookingIdFront(bookingNumber?.toString())
      .then((res) => {
        if (res === 'No booking found.') {
          setLoading(false);
          return setError('No booking found');
        }
        if (res.status === 'ok') {
          setBookingData(res?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
        console.log(err);
      });
  };

  const downloadAsPDF = () => {
    const element = document.getElementById('booking-details');

    if (!element) {
      console.error('Element with ID "booking-details" not found.');
      return;
    }

    const scaleFactor = window.devicePixelRatio || 2;
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      compress: true,
    });

    html2canvas(element, {
      scale: scaleFactor * 3,
      useCORS: true,
      logging: false,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1.0); // Max quality (1.0)

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const padding = 10;

      const imgWidth = pageWidth - padding * 2;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let yPosition = 10;

      if (imgHeight > pageHeight - 20) {
        let remainingHeight = imgHeight;
        let sourceY = 0;

        while (remainingHeight > 0) {
          pdf.addImage(
            imgData,
            'PNG',
            padding,
            yPosition,
            imgWidth,
            imgHeight,
            '',
            'FAST'
          );
          remainingHeight -= pageHeight - 20;
          sourceY += pageHeight - 20;

          if (remainingHeight > 0) {
            pdf.addPage();
          }
        }
      } else {
        pdf.addImage(
          imgData,
          'PNG',
          padding,
          yPosition,
          imgWidth,
          imgHeight,
          '',
          'FAST'
        );
      }

      const footerText =
        'This is a computer-generated booking statement and does not require any signature.';
      const footerFontSize = 12;
      const footerPadding = 10;
      const footerY = pageHeight - footerFontSize - footerPadding;

      pdf.setFontSize(footerFontSize);
      pdf.setTextColor(0, 0, 0);
      pdf.text(footerText, pageWidth / 2, footerY, { align: 'center' });

      const bookingNumber = bookingData?.references?.bookingRef || 'Unknown';
      const bookingDate = moment(bookingData?.bookingDate).format('YYMMDD');
      const fileName = `Bkg-${bookingNumber}-${bookingDate}.pdf`;

      pdf.save(fileName);
    });
  };

  return (
    <div className={'bg-main-bg dark:bg-main-dark-bg min-h-screen'}>
      <div className="m-2 md:p-5 bg-white rounded-1xl custom_design">
        {loading && (
          <div className="flex justify-center items-center">
            <BarLoader color="#072948" loading={loading} size={20} />
          </div>
        )}

        {bookingData ? (
          <div>
            {success && (
              <h5 className="text-center text_color_new auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                <CheckCircleFilled
                  style={{
                    fontSize: '22px',
                    color: '#072948',
                    marginRight: '5px',
                    position: 'relative',
                    top: '3px',
                  }}
                />
                {success}
              </h5>
            )}
            {error && (
              <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                <ExclamationCircleOutlined
                  style={{
                    fontSize: '20px',
                    color: '#FAA0A0',
                    marginRight: '5px',
                    position: 'relative',
                    top: '3px',
                  }}
                />{' '}
                {error}
              </h5>
            )}

            <br />

            <div className="flex justify-between items-center mb-5">
              <p className="hidden md:block text-2xl">
                BOOKING NUMBER: {bookingNumber ? bookingNumber : 'N/A'}
              </p>

              <button
                onClick={downloadAsPDF}
                className="bg_color_new text-white rounded-md font-medium min-w-[120px] h-full px-4 py-2"
              >
                <CloudDownloadOutlined
                  style={{
                    position: 'relative',
                    top: -3,
                  }}
                />{' '}
                PDF
              </button>
            </div>

            <div className="overflow-scroll" id="booking-details">
              <table className="w-full">
                <tbody>
                  <tr className="w-full">
                    <td className="flex items-center">
                      <span className="text-md font-bold text-red-500 mr-4">
                        UNITED CARIBBEAN
                      </span>
                      <img
                        src="https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/apcioy212oeju4hdmnxx"
                        alt="Logo"
                        className="w-[50px] h-[50px] mr-4"
                      />
                      <span className="text-md font-bold text-blue-950">
                        FREIGHT LINE
                      </span>
                    </td>
                    <td className="text-right" colSpan="3">
                      <span className="text-md bg-yellow-100 p-2 rounded-lg">
                        Booking on{' '}
                        {moment(bookingData?.bookingDate).format(
                          'YYYY MMMM DD'
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr className="mt-2 block" />

                  <tr className="border border-gray-900">
                    <td
                      colSpan="3"
                      className="text-md px-2 py-2 font-nimbusmono text-black font-bold text-left"
                    >
                      This is a booking confirmation
                    </td>

                    <td className="font-nimbusmono pr-3 font-bold text-right">
                      <span
                        className={`${
                          bookingData?.isBooked
                            ? 'bg-green-300  font-semibold p-2 rounded-md'
                            : 'text-red-500 p-2 rounded-md'
                        }`}
                      >
                        {bookingData?.isBooked ? 'CONFIRMED' : ''}
                      </span>
                    </td>
                  </tr>

                  <tr className="mt-2 block" />

                  <tr>
                    <td className="font-normal font-nimbusmono">
                      Transaction Type: {bookingData?.transactionType}
                    </td>
                    <td
                      className="font-normal font-nimbusmono text-right"
                      colSpan="3"
                    >
                      Last Submission Date/Time (UTC):{' '}
                      {moment(bookingData?.lastSubmissionDateTime)
                        .tz('UTC')
                        .format('DD/MM/YYYY hh:mm A')}
                    </td>
                  </tr>

                  <tr className="mt-2 block" />
                  <tr className="border border-gray-900">
                    <td className="text-md font-nimbusmono text-black p-2">
                      Name: {bookingData?.applicant?.name}
                      <div className="ml-2" style={{ whiteSpace: 'pre-line' }}>
                        <span className="font-normal">Address:</span>{' '}
                        {bookingData?.applicant?.address}
                      </div>
                    </td>
                    <td>
                      <span className="font-nimbusmono">Contact name:</span>
                      <span className="font-nimbusmono ml-1">
                        {bookingData?.applicant?.contact}
                      </span>
                    </td>
                    <td className="border-l border-gray-900 p-4">
                      <span className="font-semibold font-nimbusmono text-black block">
                        Reference(s)
                      </span>
                      <div>
                        <span className="font-nimbusmono">Request Ref.:</span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.references?.requestRef}
                        </span>
                      </div>
                      <div>
                        <span className="font-nimbusmono">Booking Ref.:</span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.references?.bookingRef}
                        </span>
                      </div>
                      <div>
                        <span className="font-nimbusmono">
                          Customer reference.:
                        </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.references?.customerRef}
                        </span>
                      </div>
                      <div>
                        <span className="font-nimbusmono">
                          Quote Ref. / Service Contract :
                        </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.references?.quoteRef}
                        </span>
                      </div>
                    </td>
                  </tr>

                  <tr className="mt-2 block" />
                  <tr className="border border-gray-900">
                    <td className="text-md font-nimbusmono text-black p-2">
                      <span className="font-nimbusmono block font-semibold">
                        Forwarder
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Name: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.forwarder?.name}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Adress: </span>
                        <span
                          className="font-nimbusmono ml-2"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {bookingData?.forwarder?.address}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Code: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.forwarder?.code}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Reference: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.forwarder?.reference}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Contact: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.forwarder?.contact
                            ? bookingData?.forwarder?.contact
                            : '//'}
                        </span>
                      </span>
                    </td>
                    <td className="border-l border-gray-900 p-4">
                      <span className="font-nimbusmono block font-semibold">
                        Deciding party
                      </span>
                      <span>
                        <span className="font-nimbusmono">Name: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.decidingParty?.name}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Adress: </span>
                        <span
                          className="font-nimbusmono ml-2"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {bookingData?.decidingParty?.address}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Code: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.decidingParty?.code}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Reference: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.decidingParty?.reference}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Contact: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.decidingParty?.contact
                            ? bookingData?.decidingParty?.contact
                            : '//'}
                        </span>
                      </span>
                    </td>
                    <td className="border-l border-gray-900 p-4">
                      <span className="font-semibold font-nimbusmono text-black block">
                        Notify
                      </span>
                      <span>
                        <span className="font-nimbusmono">Name: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.notify?.name}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Adress: </span>
                        <span
                          className="font-nimbusmono ml-2"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {bookingData?.notify?.address}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Code: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.notify?.code}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Reference: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.notify?.reference}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Contact: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.notify?.contact
                            ? bookingData?.notify?.contact
                            : '//'}
                        </span>
                      </span>
                    </td>
                  </tr>

                  <tr className="mt-2 block" />
                  <tr className="border border-gray-900">
                    <td className="text-md font-nimbusmono text-black p-2">
                      <span className="font-nimbusmono block font-semibold">
                        Freight Payer
                      </span>
                      <span>
                        <span className="font-nimbusmono">Name: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.freightPayer?.name}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Adress: </span>
                        <span
                          className="font-nimbusmono ml-2"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {bookingData?.freightPayer?.address}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Code: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.freightPayer?.code}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Reference: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.freightPayer?.reference}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Contact: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.freightPayer?.contact
                            ? bookingData?.freightPayer?.contact
                            : '//'}
                        </span>
                      </span>
                    </td>
                    <td className="border-l border-gray-900 p-4">
                      <span className="font-nimbusmono block font-semibold">
                        Consignee
                      </span>
                      <span>
                        <span className="font-nimbusmono">Name: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.consignee?.name}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Adress: </span>
                        <span
                          className="font-nimbusmono ml-2"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {bookingData?.consignee?.address}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Code: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.consignee?.code}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Reference: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.consignee?.reference}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Contact: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.consignee?.contact
                            ? bookingData?.consignee?.contact
                            : '//'}
                        </span>
                      </span>
                    </td>
                    <td className="border-l border-gray-900 p-4">
                      <span className="font-semibold font-nimbusmono text-black block">
                        Shipper AT LOADING PORT
                      </span>
                      <span>
                        <span className="font-nimbusmono">Name: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.shipperAtLoadingPort?.name}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Adress: </span>
                        <span
                          className="font-nimbusmono ml-2"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {bookingData?.shipperAtLoadingPort?.address}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Code: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.shipperAtLoadingPort?.code}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Reference: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.shipperAtLoadingPort?.reference}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Contact: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.shipperAtLoadingPort?.contact
                            ? bookingData?.shipperAtLoadingPort?.contact
                            : '//'}
                        </span>
                      </span>
                    </td>
                  </tr>

                  <tr className="mt-2 block" />
                  <tr className="border border-gray-900">
                    <td className="text-md font-nimbusmono text-black p-2">
                      <span className="font-nimbusmono block font-semibold">
                        Voyage Reference
                      </span>

                      <span className="font-nimbusmono">
                        {bookingData?.voyageReference}
                      </span>
                    </td>
                    <td className="border-l border-gray-900 p-4">
                      <span className="font-nimbusmono block font-semibold">
                        Vessel Name
                      </span>

                      <span className="font-nimbusmono">
                        {bookingData?.vesselName}
                      </span>
                    </td>
                    <td className="border-l border-gray-900 p-4">
                      <span className="block">
                        <span className="font-semibold font-nimbusmono text-black">
                          Export Movement Type:
                        </span>

                        <span className="font-nimbusmono ml-1">
                          {bookingData?.exportMovementType}{' '}
                        </span>
                      </span>

                      <span className="block">
                        <span className="font-semibold font-nimbusmono text-black">
                          Import Movement Type:
                        </span>

                        <span className="font-nimbusmono ml-1">
                          {bookingData?.importMovementType}{' '}
                        </span>
                      </span>
                    </td>
                  </tr>

                  <tr className="border border-gray-900">
                    <td className="text-md font-nimbusmono text-black p-2">
                      <span className="font-nimbusmono block font-semibold">
                        Place of Receipt
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Name: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.placeOfReceipt?.name}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">
                          Country / Region:{' '}
                        </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.placeOfReceipt?.country}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Expected Date: </span>
                        <span className="font-nimbusmono ml-2">
                          {moment(
                            bookingData?.placeOfReceipt?.expectedDate
                          ).format('YYYY MMMM DD')}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">
                          Cargo Ready From:{' '}
                        </span>
                        <span className="font-nimbusmono ml-2">
                          {moment(
                            bookingData?.placeOfReceipt?.cargoReadyFrom
                          ).format('YYYY MMMM DD')}
                        </span>
                      </span>
                    </td>
                    <td className="border-l border-gray-900 p-4">
                      <span className="font-nimbusmono block font-semibold">
                        Port of Loading
                      </span>

                      <span className="block">
                        <span className="font-nimbusmono">Name: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.portOfLoading?.name}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">
                          Country / Region:{' '}
                        </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.portOfLoading?.country}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Expected Date: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.portOfLoading?.expectedDate
                            ? moment(
                                bookingData?.portOfLoading?.expectedDate
                              ).format('YYYY MMMM DD')
                            : '//'}
                        </span>
                      </span>
                    </td>

                    <td className="border-l border-gray-900 p-4">
                      <span className="font-nimbusmono block font-semibold">
                        Port of Discharge
                      </span>

                      <span className="block">
                        <span className="font-nimbusmono">Name: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.portOfDischarge?.name}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">
                          Country / Region:{' '}
                        </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.portOfDischarge?.country}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">Expected Date: </span>

                        <span className="font-nimbusmono ml-2">
                          {bookingData?.portOfDischarge?.expectedDate
                            ? moment(
                                bookingData?.portOfDischarge?.expectedDate
                              ).format('YYYY MMMM DD')
                            : '//'}
                        </span>
                      </span>
                    </td>

                    <td className="border-l border-gray-900 p-4">
                      <span className="font-nimbusmono block font-semibold">
                        Place of Delivery
                      </span>

                      <span className="block">
                        <span className="font-nimbusmono">Name: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.placeOfDelivery?.name}
                        </span>
                      </span>
                      <span className="block">
                        <span className="font-nimbusmono">
                          Country / Region:{' '}
                        </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.placeOfDelivery?.country}
                        </span>
                      </span>

                      <span className="block">
                        <span className="font-nimbusmono">Expected Date: </span>
                        <span className="font-nimbusmono ml-2">
                          {bookingData?.placeOfDelivery?.expectedDate
                            ? moment(
                                bookingData?.placeOfDelivery?.expectedDate
                              ).format('YYYY MMMM DD')
                            : '//'}
                        </span>
                      </span>
                    </td>
                  </tr>

                  <tr className="mb-2 mt-5 block">
                    <td className="text-lg font-nimbusmono">Cargo</td>
                  </tr>

                  <tr>
                    <td
                      colSpan="4"
                      className="text-md border border-gray-900 px-2 py-2 text-left"
                    >
                      <span className="font-nimbusmono font-semibold block">
                        Commodity{' '}
                      </span>
                      <span className="font-nimbusmono">
                        {bookingData?.cargo?.commodity};{' '}
                        {bookingData?.cargo?.totalNetWeight}
                      </span>
                    </td>
                  </tr>

                  <tr className="my-1 block" />
                  <tr>
                    <td
                      colSpan="4"
                      className="text-md my-3 border border-gray-900 px-2 py-2 font-nimbusmono"
                    >
                      <span className="font-nimbusmono font-semibold block">
                        Container
                      </span>
                    </td>
                  </tr>

                  <tr className="border border-gray-900">
                    <td className="text-md my-3 px-2 py-2 font-nimbusmono">
                      <span className="font-nimbusmono">Equip.:</span>
                      <span className="font-nimbusmono ml-1">
                        {bookingData?.containerDetails?.equipment}
                      </span>
                    </td>
                    <td className="text-md my-3 px-2 py-2 font-nimbusmono">
                      <span className="font-nimbusmono">
                        Total cargo net weight:
                      </span>
                      <span className="font-nimbusmono ml-1">
                        {bookingData?.containerDetails?.totalCargoWeight}
                      </span>
                    </td>
                    <td className="text-md my-3 px-2 py-2 font-nimbusmono">
                      <span className="font-nimbusmono">SOC:</span>
                      <span className="font-nimbusmono ml-1">
                        {bookingData?.containerDetails?.soc ? 'Yes' : 'No'}
                      </span>
                    </td>
                  </tr>

                  <tr className="border border-gray-900">
                    <td className="text-md my-3 px-2 py-2 font-nimbusmono">
                      <span className="font-nimbusmono text-black font-semibold">
                        REEFER DETAILS
                      </span>
                    </td>
                    <td className="text-md my-3 px-2 py-2 font-nimbusmono">
                      <span className="font-nimbusmono">Reefer:</span>
                      <span className="font-nimbusmono ml-1">
                        {bookingData?.containerDetails?.reefer ? 'Yes' : 'No'}
                      </span>
                    </td>
                    <td className="text-md my-3 px-2 py-2 font-nimbusmono"></td>
                  </tr>

                  <tr className="border border-gray-900">
                    <td className="text-md my-3 px-2 py-2font-nimbusmono">
                      <span className="font-nimbusmono text-black font-semibold">
                        HAZARDOUS DETAILS
                      </span>
                    </td>
                    <td className="text-md my-3 px-2 py-2 font-nimbusmono">
                      <span className="font-nimbusmono">Hazardous goods:</span>
                      <span className="font-nimbusmono ml-1">
                        {bookingData?.containerDetails?.hazardous
                          ? 'Yes'
                          : 'No'}
                      </span>
                    </td>
                    <td className="text-md my-3 px-2 pt-1 font-nimbusmono"></td>
                  </tr>

                  <tr className="border border-gray-900">
                    <td className="text-md my-3 px-2 py-2 font-nimbusmono">
                      <span className="font-nimbusmono text-black font-semibold">
                        OOG DETAILS
                      </span>
                    </td>
                    <td className="text-md my-3 px-2 py-2 font-nimbusmono">
                      <span className="font-nimbusmono">0OG:</span>
                      <span className="font-nimbusmono ml-1">
                        {bookingData?.containerDetails?.oog ? 'Yes' : 'No'}
                      </span>
                    </td>
                    <td className="text-md my-3 px-2 pt-1 font-nimbusmono"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>
            <p className="text-center text-gray-900 text-2xl">
              {bookingNumber}
            </p>
            {!loading && !bookingData && (
              <div>
                <h5 className="text-center bold text-red-500 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                  <ExclamationCircleOutlined
                    style={{
                      fontSize: '30px',
                      color: '#FAA0A0',
                      marginRight: '5px',
                      position: 'relative',
                      top: '3px',
                    }}
                  />{' '}
                  Booking number does not exist
                </h5>
                <p className="text-gray-700 bold text-center">
                  Please note that the booking number is case-sensitive. Kindly
                  ensure you enter it correctly.
                </p>

                <div className="my-5 flex justify-center items-center align-middle">
                  <div className="notfound">
                    <Link to="/" as={NavLink}>
                      Go to home
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingDetails;
