import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const Tracking = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('tracking'); 

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (activeTab === 'tracking') {
      const { blNumber } = data;
      navigate(`/tracking/${blNumber}`);
    } else if (activeTab === 'booking') {
      const { bookingNumber } = data;
      navigate(`/booking-details/${bookingNumber}`);
    }
  };

  return (
    <div className="text-white">
      <div className="text-center mb-8">
        <h3 className="text-3xl">Tracking</h3>
        <p className="my-5 text-md">
          {activeTab === 'tracking'
            ? 'Track the progress of your shipment by entering your unique BL number in the field below.'
            : 'Search for your booking details by entering your unique Booking Number in the field below.'}
        </p>
      </div>

      {/* Tabs */}
      <div className="flex justify-center space-x-4 mb-8">
        <button
          onClick={() => setActiveTab('tracking')}
          className={`px-6 py-2 rounded-md font-medium ${
            activeTab === 'tracking'
              ? 'bg_color_new text-white'
              : 'bg-gray-700 text-gray-300'
          }`}
        >
          BL Tracking
        </button>
        <button
          onClick={() => setActiveTab('booking')}
          className={`px-6 py-2 rounded-md font-medium ${
            activeTab === 'booking'
              ? 'bg_color_new text-white'
              : 'bg-gray-700 text-gray-300'
          }`}
        >
          Booking
        </button>
      </div>

      {/* Form */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0">
          <input
            {...register(activeTab === 'tracking' ? 'blNumber' : 'bookingNumber', {
              required: `${activeTab === 'tracking' ? 'BL Number' : 'Booking Number'} is required`,
            })}
            className="p-3 w-[288px] sm:min-w-[320px] h-full rounded-md text-black text-center border border-gray-300"
            type="text"
            placeholder={activeTab === 'tracking' ? 'BL NUMBER' : 'BOOKING NUMBER'}
            style={{
              fontFamily: 'sans-serif',
            }}
          />
        </div>
        {errors.blNumber && activeTab === 'tracking' && (
          <p className="text-red-500 text-md pt-2 text-center">
            {errors.blNumber.message}
          </p>
        )}
        {errors.bookingNumber && activeTab === 'booking' && (
          <p className="text-red-500 text-md pt-2 text-center">
            {errors.bookingNumber.message}
          </p>
        )}
        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="bg_color_new text-white rounded-md font-medium min-w-[288px] sm:min-w-[320px] h-full px-4 py-2"
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default Tracking;