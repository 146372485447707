export const blogData = [
  {
    id: 1,
    image:
      'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/blog%20img/teapb46wqjyhqjpuukji',
    date: '2024-09-11T10:31:29.621+00:00',
    title: 'Warehousing and packaging of goods in transit:',
    description: [
      'Efficient warehousing and packaging of goods in transit are essential for a well-functioning supply chain. These processes play a crucial role in ensuring the safe storage, protection, and organized movement of products as they progress through different stages of transportation.',
      'Warehousing involves strategically allocating goods within a facility to optimize space utilization and accessibility, thereby enabling easy retrieval and distribution when required. Effective warehousing encompasses inventory management, tracking, and regular inspections to uphold the quality of the goods.',
      'Packaging plays a crucial role as it protects products from damage during handling, loading, and unloading. Proper packaging shields items from external factors such as moisture, vibrations, and temperature fluctuations. It also helps minimize the risk of loss or theft during transit.',
    ],
  },
  {
    id: 2,
    image:
      'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/blog%20img/jo0rxdibhulk9nqcdpy6',
    date: '2024-09-11T10:31:29.621+00:00',
    title: 'Freight Forwarding service by UCF-Line',
    description: [
      'International Freight Forwarding Company UK',
      'Experts in quick & cost-effective global door-to-door delivery',
      'We are an international shipping and freight forwarding company based in the UK that is dedicated, irrespective of size, distance and time constraints, to getting your consignment to its final destination.',
      'We are an international freight shipping company based in UK, EUROPE AND USA.',
      'Our haulage and courier firm provide unrivalled, bespoke worldwide cargo and pallet shipping solutions for businesses and individuals alike. From pallet delivery to shipping parcels, we are a UK-based freight forwarding firm you can trust that is a short distance from the M25, and numerous local and international air and maritime ports.',
      'An international shipping company you can trust. The current economic environment requires a haulage supplier you can trust. Here at Barrington Freight, we are one of the most trusted freight carrier companies in the UK. As a member of the British International Freight Association (BIFA) we provide a professional transport service backed by comprehensive liability insurance. Your shipment is in safe hands.',
    ],
  },
  {
    id: 3,
    image:
      'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/blog%20img/czt8zxfx3dzcivwtxkon',
    date: '2024-09-11T10:31:29.621+00:00',
    title:
      'TRANSSHIPMENT YARD FOR OVERSEAS CONSIGNMENT AND CARGO RE-ROUTING ROUTINES',
    description: [
      'No individuals desire to encounter any form of losses during the transportation process. Consequently, there is a collective interest in obtaining superior tracking resources for monitoring the arrival and anticipated delivery time of shipments. Subsequently, in the context of tracking, once the transshipped container is loaded onto the new vessel, the consignee will execute and dispatch a bill of lading.',
      'The best way to check the current status of your shipment is by using the online tracking facility provided by your transportation service provider. This feature allows you to access live tracking information and receive notifications about the status and location of your cargo in real time.',
    ],
  },
];
