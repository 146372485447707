import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import {
  MdEmail,
  MdKeyboardArrowDown,
  MdPhone,
  MdSearch,
} from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [scroll, setScroll] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigation = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [searchType, setSearchType] = useState('blNumber');
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const toggleSubMenu = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  const handleSectionNavigation = (section) => {
    navigate('/', { state: { scrollToSection: section } });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const { searchValue } = data;
    if (!searchValue) {
      toast.error('Field must not be empty');
      return;
    }
    if (searchType === 'blNumber') {
      navigate(`/tracking/${searchValue}`);
    } else if (searchType === 'bookingNumber') {
      navigate(`/booking-details/${searchValue}`);
    }
  };

  return (
    <div
      className={
        scroll
          ? 'fixed top-0 left-0 w-full text-black h-14 transition-all duration-500 z-50'
          : 'w-full mx-auto text-black transition-all duration-300 z-50'
      }
    >
      <div
        className={`w-full bg-white transition-transform duration-300`}
        style={{ zIndex: 999 }}
      >
        <div className="container mx-auto p-4 flex justify-between items-center relative">
          <div className="lg:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="mr-4 text-xl"
            >
              ☰
            </button>
          </div>

          <div
            className="lg:hidden absolute left-1/2 transform -translate-x-1/2 text-2xl font-bold text-sky-800 text-center cursor-pointer"
            onClick={() => navigation('/')}
          >
            <NavLink to="/" className="flex items-center">
              <img
                src="https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/apcioy212oeju4hdmnxx"
                alt="Logo"
                className="w-8 h-8 lg:w-12 lg:h-12"
              />
              <p className="ml-2 text-xl lg:text-3xl font-bold text-black uppercase">
                UCFL
              </p>
            </NavLink>
          </div>

          <div className="lg:hidden flex items-center">
            <button onClick={() => setIsSearchOpen(!isSearchOpen)}>
              <MdSearch color="#888" size={30} />
            </button>
          </div>

          <div className="hidden lg:flex justify-between items-center w-full">
            <div
              className="text-2xl font-bold text-sky-800 cursor-pointer"
              onClick={() => navigation('/')}
            >
              <NavLink to="/" className="flex items-center">
                <img
                  src="https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/apcioy212oeju4hdmnxx"
                  alt="Logo"
                  className="w-8 h-8 lg:w-12 lg:h-12"
                />
                <p className="ml-2 text-xl lg:text-3xl font-bold text-black uppercase">
                  UCFL
                </p>
              </NavLink>
            </div>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex items-center relative w-1/2"
              >
                <select
                  value={searchType}
                  onChange={(e) => setSearchType(e.target.value)}
                  className="border rounded-l-lg h-[39px] px-3 bg-white focus:outline-none"
                >
                  <option value="blNumber">BL NUMBER</option>
                  <option value="bookingNumber">BOOKING NUMBER</option>
                </select>
                <input
                  {...register('searchValue', {
                    required: `${searchType === 'blNumber' ? 'BL Number' : 'Booking Number'} is required`,
                  })}
                  type="text"
                  placeholder={searchType === 'blNumber' ? 'TRACK WITH BL NUMBER' : 'SEARCH WITH BOOKING NUMBER'}
                  className="border rounded-r-lg px-4 py-2 w-full focus:outline-none"
                />
                <button className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-transparent">
                  <MdSearch color="#888" size={30} />
                </button>
              </form>

            <div className="flex items-center space-x-1">
              <MdEmail color="#072948" size={24} />
              <p className="lg:ml-1 ml-0">info@ucfline.com</p>
            </div>
          </div>
        </div>

        {isSearchOpen && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex items-center relative w-full ml-0 pb-2"
          >
            <select
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              className="border rounded-l-lg px-4 py-2 bg-white"
            >
              <option value="blNumber">BL NUMBER</option>
              <option value="bookingNumber">BOOKING NUMBER</option>
            </select>
            <input
              {...register('searchValue', {
                required: `${searchType === 'blNumber' ? 'BL Number' : 'Booking Number'} is required`,
              })}
              type="text"
              placeholder={searchType === 'blNumber' ? 'BL NUMBER' : 'BOOKING NUMBER'}
              className="border rounded-r-lg px-4 py-2 w-full"
            />
            <button className="absolute right-2 top-5 transform -translate-y-1/2 bg-transparent">
              <MdSearch color="#888" size={30} />
            </button>
          </form>
        )}
      </div>

      <div className="hidden lg:block w-full bg-white border-t border-gray-300 z-50">
        <div className="container mx-auto p-4 flex justify-between items-center">
          <nav className="flex space-x-4">
            <ul className="hidden md:flex cursor-pointer">
              <li
                className="px-4 uppercase font-bold hover:text-red-600 cursor-pointer"
                onClick={() => navigate('/')}
              >
                Home
              </li>

              <li
                className="px-4 uppercase font-bold hover:text-red-600 cursor-pointer"
                onClick={() => handleSectionNavigation('about')}
              >
                About
              </li>

              <li
                className="px-4 uppercase font-bold hover:text-red-600 cursor-pointer"
                onClick={() => handleSectionNavigation('service')}
              >
                Service
              </li>

              <li
                className="px-4 uppercase font-bold hover:text-red-600 cursor-pointer"
                onClick={() => handleSectionNavigation('schedule')}
              >
                Schedule
              </li>

              <NavLink to="/booking" className="hover:text-red-600">
                <li className="px-4 uppercase font-bold">Booking</li>
              </NavLink>

              <NavLink to="/news" className="hover:text-red-600">
                <li className="px-4 uppercase font-bold">News</li>
              </NavLink>

              <NavLink to="/blogpage" className="hover:text-red-600">
                <li className="px-4 uppercase font-bold">Blog</li>
              </NavLink>

              <li
                className="px-4 uppercase font-bold hover:text-red-600 cursor-pointer"
                onClick={() => handleSectionNavigation('contact')}
              >
                Contact
              </li>
            </ul>
          </nav>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <MdEmail color="#075985" size={24} />
              <span>sales@ucfline.com</span>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-50 transform ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 lg:hidden`}
        style={{ zIndex: 1001 }}
      >
        <div
          ref={menuRef}
          className="bg-white w-64 h-full p-4 fixed top-0 left-0"
        >
          <div className="flex justify-between items-center mb-4">
            <NavLink to="/" className="flex items-center">
              <img
                src="https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/apcioy212oeju4hdmnxx"
                alt="Logo"
                className="w-8 h-8 lg:w-12 lg:h-12"
              />
              <p className="ml-2 text-xl lg:text-3xl font-bold text-black uppercase">
                UCFL
              </p>
            </NavLink>
            <button onClick={() => setIsMenuOpen(false)}>✕</button>
          </div>

          <nav className="flex flex-col space-y-4">
            <ul className="cursor-pointer">
              <li
                className="py-4 uppercase font-bold hover:text-red-600 cursor-pointer"
                onClick={() => navigate('/')}
              >
                Home
              </li>

              <li
                className="py-4 uppercase font-bold hover:text-red-600 cursor-pointer"
                onClick={() => handleSectionNavigation('about')}
              >
                About
              </li>

              <li
                className="py-4 uppercase font-bold hover:text-red-600 cursor-pointer"
                onClick={() => handleSectionNavigation('service')}
              >
                Service
              </li>

              <li
                className="py-4 uppercase font-bold hover:text-red-600 cursor-pointer"
                onClick={() => handleSectionNavigation('schedule')}
              >
                Schedule
              </li>

              <NavLink to="/booking" className="hover:text-red-600">
                <li className="py-4 uppercase font-bold">Booking</li>
              </NavLink>

              <NavLink to="/news" className="hover:text-red-600">
                <li className="py-4 uppercase font-bold">News</li>
              </NavLink>

              <NavLink to="/blogpage" className="hover:text-red-600">
                <li className="py-4 uppercase font-bold">Blog</li>
              </NavLink>

              <li
                className="py-4 uppercase font-bold hover:text-red-600 cursor-pointer"
                onClick={() => handleSectionNavigation('contact')}
              >
                Contact
              </li>
            </ul>
          </nav>
          <div className="mt-4">
            <div className="flex items-center space-x-2 mt-2">
              <MdEmail color="#075985" size={24} />
              <span>admin@emailbot.email</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;