import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useStateContext } from '../components/contexts/ContextProvider';
import AdminSidebar from '../components/sidebar/AdminSidebar';
import { useForm } from 'react-hook-form';
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import BarLoader from 'react-spinners/BarLoader';
import NavbarAdmin from '../components/nav/Navbar';
import {
  addBookingFront,
  deleteBookingFront,
  getAllBookingsFront,
  getBookingDetailsByIdFront,
  updateBookingFront,
} from '../components/functions/booking';

const cancelIcon = require('../assets/cancel-icon.png');

const AdminBooking = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const modalRef = useRef(null);

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { activeMenu } = useStateContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [bookingDetails, setBookingDetails] = useState(null);
  const [loadingBooking, setLoadingBooking] = useState(false);

  const [showDataModal, setShowDataModal] = useState(false);

  const [loadingData, setLoadingData] = useState(false);

  const [data, setData] = useState([]);
  const [successData, setSuccessData] = useState('');
  const [errorData, setErrorData] = useState('');

  const {
    register: registerData,
    formState: { errors: errorsDataForm },
    handleSubmit: handleDataSubmit,
    reset: resetData,
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  const loadData = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const res = await getAllBookingsFront(user?.token);

      if (res === 'No bookings found.') {
        setLoading(false);
        return setError('No bookings found');
      }

      if (res.status === 'ok') {
        setData(res.data.data);
      } else {
        setError(res || 'Failed to load bookings');
      }
    } catch (err) {
      console.error('Error fetching bookings:', err);
      toast.error(err?.response?.data?.message || 'Something went wrong.');
      setError(err?.response?.data?.message || 'An error occurred.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const openModal = async (bookingId) => {
    setLoadingBooking(true);

    try {
      const response = await getBookingDetailsByIdFront(bookingId);
      if (response.status === 'ok') {
        setBookingDetails(response?.data?._id);

        const formattedBookingDate = response.data.bookingDate
          ? new Date(response?.data?.bookingDate).toISOString().split('T')[0]
          : '';

        const formattedLastSubmissionDate = response?.data
          ?.lastSubmissionDateTime
          ? new Date(response?.data?.lastSubmissionDateTime)
              .toISOString()
              .split('T')[0]
          : '';

        const formattedLastSubmissionTime = response?.data
          ?.lastSubmissionDateTime
          ? new Date(response?.data?.lastSubmissionDateTime)
              .toISOString()
              .split('T')[1]
              .slice(0, 5)
          : '';

        setValue(
          'bookingNumber',
          response?.data?.bookingNumber?.toString() || ''
        );
        setValue('bookingDate', formattedBookingDate);
        setValue('lastSubmissionDate', formattedLastSubmissionDate);
        setValue('lastSubmissionTime', formattedLastSubmissionTime);

        setValue(
          'applicant.name',
          response?.data?.applicant?.name?.toString() || ''
        );
        setValue(
          'applicant.address',
          response?.data?.applicant?.address?.toString() || ''
        );
        setValue(
          'applicant.contact',
          response.data.applicant?.contact?.toString() || ''
        );

        setValue(
          'references.requestRef',
          response?.data?.references?.requestRef?.toString() || ''
        );
        setValue(
          'references.bookingRef',
          response?.data?.references?.bookingRef?.toString() || ''
        );
        setValue(
          'references.customerRef',
          response?.data?.references?.customerRef?.toString() || ''
        );
        setValue(
          'references.quoteRef',
          response?.data?.references?.quoteRef?.toString() || ''
        );

        setValue('forwarder.name', response?.data?.forwarder?.name || '');
        setValue('forwarder.address', response?.data?.forwarder?.address || '');
        setValue('forwarder.code', response?.data?.forwarder?.code || '');
        setValue(
          'forwarder.reference',
          response?.data?.forwarder?.reference || ''
        );
        setValue('forwarder.contact', response?.data?.forwarder?.contact || '');

        setValue(
          'decidingParty.name',
          response?.data?.decidingParty?.name || ''
        );
        setValue(
          'decidingParty.address',
          response?.data?.decidingParty?.address || ''
        );
        setValue(
          'decidingParty.code',
          response?.data?.decidingParty?.code || ''
        );
        setValue(
          'decidingParty.reference',
          response?.data?.decidingParty?.reference || ''
        );
        setValue(
          'decidingParty.contact',
          response?.data?.decidingParty?.contact || ''
        );

        setValue('notify.name', response?.data?.notify?.name || '');
        setValue('notify.address', response?.data?.notify?.address || '');
        setValue('notify.code', response?.data?.notify?.code || '');
        setValue('notify.reference', response?.data?.notify?.reference || '');
        setValue('notify.contact', response?.data?.notify?.contact || '');

        setValue('freightPayer.name', response?.data?.freightPayer?.name || '');
        setValue(
          'freightPayer.address',
          response?.data?.freightPayer?.address || ''
        );
        setValue('freightPayer.code', response?.data?.freightPayer?.code || '');
        setValue(
          'freightPayer.reference',
          response?.data?.freightPayer?.reference || ''
        );
        setValue(
          'freightPayer.contact',
          response?.data?.freightPayer?.contact || ''
        );

        setValue('consignee.name', response?.data.consignee?.name || '');
        setValue('consignee.address', response?.data.consignee?.address || '');
        setValue('consignee.code', response?.data.consignee?.code || '');
        setValue(
          'consignee.reference',
          response?.data.consignee?.reference || ''
        );
        setValue('consignee.contact', response?.data.consignee?.contact || '');

        setValue(
          'shipper.name',
          response?.data.shipperAtLoadingPort?.name || ''
        );
        setValue(
          'shipper.address',
          response?.data.shipperAtLoadingPort?.address || ''
        );
        setValue(
          'shipper.code',
          response?.data.shipperAtLoadingPort?.code || ''
        );
        setValue(
          'shipper.reference',
          response?.data.shipperAtLoadingPort?.reference || ''
        );
        setValue(
          'shipper.contact',
          response?.data.shipperAtLoadingPort?.contact || ''
        );

        setValue('voyageReference', response?.data.voyageReference || '');
        setValue('vesselName', response?.data.vesselName || '');
        setValue('exportMovementType', response?.data.exportMovementType || '');
        setValue('importMovementType', response?.data.importMovementType || '');

        setValue(
          'placeOfReceipt.name',
          response?.data.placeOfReceipt?.name || ''
        );
        setValue(
          'placeOfReceipt.country',
          response?.data.placeOfReceipt?.country || ''
        );
        setValue(
          'placeOfReceipt.expectedDate',
          response?.data.placeOfReceipt?.expectedDate
            ? new Date(response.data.placeOfReceipt.expectedDate)
                .toISOString()
                .split('T')[0]
            : ''
        );
        setValue(
          'placeOfReceipt.cargoReadyFrom',
          response?.data.placeOfReceipt?.cargoReadyFrom
            ? new Date(response.data.placeOfReceipt.cargoReadyFrom)
                .toISOString()
                .split('T')[0]
            : ''
        );

        setValue(
          'portOfLoading.name',
          response?.data.portOfLoading?.name || ''
        );
        setValue(
          'portOfLoading.unCode',
          response?.data.portOfLoading?.unCode || ''
        );
        setValue(
          'portOfLoading.country',
          response?.data.portOfLoading?.country || ''
        );
        setValue(
          'portOfLoading.expectedDate',

          response?.data.portOfLoading?.expectedDate
            ? new Date(response.data.portOfLoading.expectedDate)
                .toISOString()
                .split('T')[0]
            : ''
        );

        setValue(
          'portOfDischarge.name',
          response?.data.portOfDischarge?.name || ''
        );
        setValue(
          'portOfDischarge.unCode',
          response?.data.portOfDischarge?.unCode || ''
        );
        setValue(
          'portOfDischarge.country',
          response?.data.portOfDischarge?.country || ''
        );
        setValue(
          'portOfDischarge.expectedDate',
          response?.data.portOfDischarge?.expectedDate
            ? new Date(response.data.portOfDischarge.expectedDate)
                .toISOString()
                .split('T')[0]
            : ''
        );

        setValue(
          'placeOfDelivery.name',
          response?.data.placeOfDelivery?.name || ''
        );
        setValue(
          'placeOfDelivery.unCode',
          response?.data.placeOfDelivery?.unCode || ''
        );
        setValue(
          'placeOfDelivery.country',
          response?.data.placeOfDelivery?.country || ''
        );
        setValue(
          'placeOfDelivery.expectedDate',

          response?.data.placeOfDelivery?.expectedDate
            ? new Date(response.data.placeOfDelivery.expectedDate)
                .toISOString()
                .split('T')[0]
            : ''
        );

        setValue('cargo.commodity', response?.data.cargo?.commodity || '');
        setValue(
          'cargo.totalNetWeight',
          response?.data.cargo?.totalNetWeight || ''
        );

        setValue(
          'container.equipment',
          response?.data.containerDetails?.equipment || ''
        );
        setValue(
          'container.totalCargoWeight',
          response?.data.containerDetails?.totalCargoWeight?.toString() || ''
        );
        setValue(
          'container.reefer',
          response?.data.containerDetails?.reefer || false
        );
        setValue(
          'container.hazardous',
          response?.data.containerDetails?.hazardous || false
        );
        setValue(
          'container.oog',
          response?.data.containerDetails?.oog || false
        );
        setValue(
          'container.soc',
          response?.data.containerDetails?.soc || false
        );

        setValue('isBooked', response?.data.isBooked || false);

        setShowDataModal(true);
      }
    } catch (error) {
      console.error('Error fetching booking details:', error);
    } finally {
      setLoadingBooking(false);
    }
  };

  const closeModal = () => {
    resetData();
    setBookingDetails(null);
    setShowDataModal(false);
  };

  const logout = () => {
    Cookies.remove('user');
    dispatch({
      type: 'LOGOUT',
      payload: null,
    });
    navigation('/login');
  };

  const handleSubmitForm = async (data) => {
    if (modalRef.current) {
      modalRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const lastSubmissionDateTime =
      data.lastSubmissionDate && data.lastSubmissionTime
        ? new Date(
            `${data.lastSubmissionDate}T${data.lastSubmissionTime}:00Z`
          ).toISOString()
        : null;
    try {
      const formData = {
        bookingNumber: data.bookingNumber,
        bookingDate: data.bookingDate,
        lastSubmissionDateTime,
        applicant: data.applicant,
        references: data.references,
        forwarder: data.forwarder,
        decidingParty: data.decidingParty,
        notify: data.notify,
        freightPayer: data.freightPayer,
        consignee: data.consignee,
        shipperAtLoadingPort: data.shipper,
        voyageReference: data?.voyageReference,
        vesselName: data?.vesselName,
        exportMovementType: data?.exportMovementType,
        importMovementType: data?.importMovementType,
        placeOfReceipt: {
          name: data.placeOfReceipt?.name,
          country: data.placeOfReceipt?.country,
          expectedDate: data.placeOfReceipt?.expectedDate
            ? new Date(data.placeOfReceipt.expectedDate)
            : null,
          cargoReadyFrom: data.placeOfReceipt?.cargoReadyFrom
            ? new Date(data.placeOfReceipt.cargoReadyFrom)
            : null,
        },
        portOfLoading: data.portOfLoading,
        portOfDischarge: data.portOfDischarge,
        placeOfDelivery: data.placeOfDelivery,
        cargo: {
          commodity: data.cargo?.commodity,
          totalNetWeight: Number(data.cargo?.totalNetWeight) || '',
        },
        containerDetails: {
          equipment: data.container?.equipment,
          totalCargoWeight: data.container?.totalCargoWeight,
          reefer: data.container?.reefer,
          hazardous: data.container?.hazardous,
          oog: data.container?.oog,
          soc: data.container?.soc,
        },
        isBooked: data.isBooked || false,
      };

      const res = await addBookingFront(formData, user?.token, setErrorData);

      if (res && res.status === 'ok') {
        setErrorData('');
        setSuccessData('Booking added successfully');
        setTimeout(() => {
          setSuccessData('');
          loadData();
          resetData();
          setShowDataModal(false);
        }, 2000);
      }

      if (!res || res.error) {
        throw new Error(res?.error || 'Booking submission failed');
      }
    } catch (err) {
      console.error('MongoDB Save Error:', err);
    }
  };

  const handleEditForm = async (data) => {
    if (modalRef.current) {
      modalRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const lastSubmissionDateTime =
      data.lastSubmissionDate && data.lastSubmissionTime
        ? new Date(
            `${data.lastSubmissionDate}T${data.lastSubmissionTime}:00Z`
          ).toISOString()
        : null;

    try {
      const formData = {
        bookingNumber: data.bookingNumber,
        bookingDate: data.bookingDate,
        lastSubmissionDateTime,
        applicant: data.applicant,
        references: data.references,
        forwarder: data.forwarder,
        decidingParty: data.decidingParty,
        notify: data.notify,
        freightPayer: data.freightPayer,
        consignee: data.consignee,
        shipperAtLoadingPort: data.shipper,
        voyageReference: data?.voyageReference,
        vesselName: data?.vesselName,
        exportMovementType: data?.exportMovementType,
        importMovementType: data?.importMovementType,
        placeOfReceipt: {
          name: data.placeOfReceipt?.name,
          country: data.placeOfReceipt?.country,
          expectedDate: data.placeOfReceipt?.expectedDate
            ? new Date(data.placeOfReceipt.expectedDate)
            : null,
          cargoReadyFrom: data.placeOfReceipt?.cargoReadyFrom
            ? new Date(data.placeOfReceipt.cargoReadyFrom)
            : null,
        },
        portOfLoading: data.portOfLoading,
        portOfDischarge: data.portOfDischarge,
        placeOfDelivery: data.placeOfDelivery,
        cargo: {
          commodity: data.cargo?.commodity,
          totalNetWeight: Number(data.cargo?.totalNetWeight) || '',
        },
        containerDetails: {
          equipment: data.container?.equipment,
          totalCargoWeight: data.container?.totalCargoWeight,
          reefer: data.container?.reefer,
          hazardous: data.container?.hazardous,
          oog: data.container?.oog,
          soc: data.container?.soc,
        },
        isBooked: data.isBooked || false,
      };

      if (!bookingDetails) {
        throw new Error('Booking ID is missing for update.');
      }

      const res = await updateBookingFront(
        bookingDetails,
        formData,
        user?.token
      );

      if (res && res.status === 'ok') {
        setErrorData('');
        setSuccessData('Booking updated successfully');
        setTimeout(() => {
          setSuccessData('');
          loadData();
          resetData();
          setShowDataModal(false);
        }, 2000);
      }

      if (!res || res.error) {
        throw new Error(res?.error || 'Booking update failed');
      }
    } catch (err) {
      console.error('MongoDB Update Error:', err);
    }
  };

  const handleRemove = async (title, id) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setLoading(true);
      try {
        await deleteBookingFront(id, user?.token);
        toast.success(`"${title}" is deleted`);
        setLoading(false);
        window.location.reload();
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
          <NavbarAdmin />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl custom_design">
          <p className="text-2xl tracking-tight text-slate-600">
            <span className="font-bold">Manage Booking</span>
          </p>
          {loading && (
            <div className="flex justify-center items-center">
              <BarLoader color="#004a93" loading={loading} size={20} />
            </div>
          )}
          {success && (
            <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
              <CheckCircleFilled
                style={{
                  fontSize: '22px',
                  color: '#50C878',
                  marginRight: '5px',
                  position: 'relative',
                  top: '3px',
                }}
              />
              {success}
            </h5>
          )}
          {error && (
            <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
              <ExclamationCircleOutlined
                style={{
                  fontSize: '20px',
                  color: '#FAA0A0',
                  marginRight: '5px',
                  position: 'relative',
                  top: '3px',
                }}
              />{' '}
              {error}
            </h5>
          )}

          <br />
          <button
            onClick={() => setShowDataModal(true)}
            className="new_role_btn"
          >
            Add new
          </button>

          {showDataModal && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50 overflow-y-scroll">
              <div
                ref={modalRef}
                className="bg-white rounded-lg shadow-lg sm:w-4/5 md:w-1/2 pb-2 overflow-y-auto max-h-[90vh]"
              >
                <div className="bg-gray-100 border-b px-4 py-6 flex justify-between items-center rounded-lg">
                  <h3 className="font-semibold text-xl text-stone-600">
                    {bookingDetails ? 'Update Booking' : 'Add New Booking'}
                  </h3>

                  <div className="flex">
                    <button
                      onClick={closeModal}
                      className="text-black close-modal"
                    >
                      <img src={cancelIcon} alt="Cancel" />
                    </button>
                  </div>
                </div>

                <div className="p-6">
                  {loadingData && (
                    <div className="flex justify-center items-center">
                      <BarLoader
                        color="#004a93"
                        loading={loadingData}
                        size={20}
                      />
                    </div>
                  )}

                  {successData && (
                    <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                      <CheckCircleFilled
                        style={{
                          fontSize: '22px',
                          color: '#50C878',
                          marginRight: '5px',
                          position: 'relative',
                          top: '3px',
                        }}
                      />
                      {successData}
                    </h5>
                  )}

                  {errorData && (
                    <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                      <ExclamationCircleOutlined
                        style={{
                          fontSize: '20px',
                          color: '#FAA0A0',
                          marginRight: '5px',
                          position: 'relative',
                          top: '3px',
                        }}
                      />{' '}
                      {errorData}
                    </h5>
                  )}

                  <form
                    onSubmit={
                      bookingDetails
                        ? handleDataSubmit(handleEditForm)
                        : handleDataSubmit(handleSubmitForm)
                    }
                  >
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="bookingNumber"
                      >
                        Booking Number <span className="text-red-500">*</span>
                      </label>
                      <input
                        {...registerData('bookingNumber', {
                          required: 'Booking number is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="bookingNumber"
                        type="text"
                        placeholder="Booking Number"
                      />

                      {errorsDataForm.bookingNumber && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsDataForm.bookingNumber.message}
                        </p>
                      )}
                    </div>

                    {/* Booking Date */}
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="bookingDate"
                      >
                        Booking Date <span className="text-red-500">*</span>
                      </label>
                      <input
                        {...registerData('bookingDate', {
                          required: 'Booking date is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="bookingDate"
                        type="date"
                        placeholder="Booking Date"
                      />

                      {errorsDataForm.bookingDate && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsDataForm.bookingDate.message}
                        </p>
                      )}
                    </div>

                    {/* Last Submission Date */}
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="lastSubmissionDate"
                      >
                        Last Submission Date{' '}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        {...registerData('lastSubmissionDate', {
                          required: 'Last submission date is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="lastSubmissionDate"
                        type="date"
                      />

                      {errorsDataForm.lastSubmissionDate && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsDataForm.lastSubmissionDate.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="lastSubmissionTime"
                      >
                        Last Submission Time{' '}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        {...registerData('lastSubmissionTime', {
                          required: 'Last submission time is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="lastSubmissionTime"
                        type="time"
                      />

                      {errorsDataForm.lastSubmissionTime && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsDataForm.lastSubmissionTime.message}
                        </p>
                      )}
                    </div>

                    {/* Applicant Details */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Applicant Details
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="applicant.name"
                      >
                        Name
                      </label>
                      <input
                        {...registerData('applicant.name')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="applicant.name"
                        type="text"
                        placeholder="Applicant Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="applicant.address"
                      >
                        Address
                      </label>
                      <textarea
                        {...registerData('applicant.address')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="applicant.address"
                        rows="3"
                        placeholder="Enter applicant address"
                      ></textarea>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="applicant.contact"
                      >
                        Contact
                      </label>
                      <input
                        {...registerData('applicant.contact')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="applicant.contact"
                        type="text"
                        placeholder="Applicant Contact"
                      />
                    </div>

                    {/* Reference Details */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Reference Details
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="references.requestRef"
                      >
                        Request Reference
                      </label>
                      <input
                        {...registerData('references.requestRef')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="references.requestRef"
                        type="text"
                        placeholder="Request Reference"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="references.bookingRef"
                      >
                        Booking Reference
                      </label>
                      <input
                        {...registerData('references.bookingRef')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="references.bookingRef"
                        type="text"
                        placeholder="Booking Reference"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="references.customerRef"
                      >
                        Customer Reference
                      </label>
                      <input
                        {...registerData('references.customerRef')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="references.customerRef"
                        type="text"
                        placeholder="Customer Reference"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="references.quoteRef"
                      >
                        Quote Reference
                      </label>
                      <input
                        {...registerData('references.quoteRef')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="references.quoteRef"
                        type="text"
                        placeholder="Quote Reference"
                      />
                    </div>

                    {/* Forwarder Details */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Forwarder Details
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="forwarder.name"
                      >
                        Name
                      </label>
                      <input
                        {...registerData('forwarder.name')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="forwarder.name"
                        type="text"
                        placeholder="Forwarder Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="forwarder.address"
                      >
                        Address
                      </label>
                      <textarea
                        {...registerData('forwarder.address')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="forwarder.address"
                        rows="3"
                        placeholder="Enter forwarder address"
                      ></textarea>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="forwarder.code"
                      >
                        Code
                      </label>
                      <input
                        {...registerData('forwarder.code')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="forwarder.code"
                        type="text"
                        placeholder="Forwarder Code"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="forwarder.reference"
                      >
                        Reference
                      </label>
                      <input
                        {...registerData('forwarder.reference')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="forwarder.reference"
                        type="text"
                        placeholder="Forwarder Reference"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="forwarder.contact"
                      >
                        Contact
                      </label>
                      <input
                        {...registerData('forwarder.contact')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="forwarder.contact"
                        type="text"
                        placeholder="Forwarder Contact"
                      />
                    </div>

                    {/* Deciding Party Details */}

                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Deciding Party Details
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="decidingParty.name"
                      >
                        Name
                      </label>
                      <input
                        {...registerData('decidingParty.name')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="decidingParty.name"
                        type="text"
                        placeholder="Deciding Party Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="decidingParty.address"
                      >
                        Address
                      </label>
                      <textarea
                        {...registerData('decidingParty.address')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="decidingParty.address"
                        rows="3"
                        placeholder="Enter deciding party address"
                      ></textarea>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="decidingParty.code"
                      >
                        Code
                      </label>
                      <input
                        {...registerData('decidingParty.code')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="decidingParty.code"
                        type="text"
                        placeholder="Deciding Party Code"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="decidingParty.reference"
                      >
                        Reference
                      </label>
                      <input
                        {...registerData('decidingParty.reference')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="decidingParty.reference"
                        type="text"
                        placeholder="Deciding Party Reference"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="decidingParty.contact"
                      >
                        Contact
                      </label>
                      <input
                        {...registerData('decidingParty.contact')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="decidingParty.contact"
                        type="text"
                        placeholder="Deciding Party Contact"
                      />
                    </div>

                    {/* Notify party Details */}

                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Notify Party Details
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="notify.name"
                      >
                        Name
                      </label>
                      <input
                        {...registerData('notify.name')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="notify.name"
                        type="text"
                        placeholder="Notify Party Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="notify.address"
                      >
                        Address
                      </label>
                      <textarea
                        {...registerData('notify.address')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="notify.address"
                        rows="3"
                        placeholder="Enter notify party address"
                      ></textarea>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="notify.code"
                      >
                        Code
                      </label>
                      <input
                        {...registerData('notify.code')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="notify.code"
                        type="text"
                        placeholder="Notify Party Code"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="notify.reference"
                      >
                        Reference
                      </label>
                      <input
                        {...registerData('notify.reference')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="notify.reference"
                        type="text"
                        placeholder="Notify Party Reference"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="notify.contact"
                      >
                        Contact
                      </label>
                      <input
                        {...registerData('notify.contact')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="notify.contact"
                        type="text"
                        placeholder="Notify Party Contact"
                      />
                    </div>

                    {/* Freight Payer Details */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Freight Payer Details
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="freightPayer.name"
                      >
                        Name
                      </label>
                      <input
                        {...registerData('freightPayer.name')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="freightPayer.name"
                        type="text"
                        placeholder="Freight Payer Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="freightPayer.address"
                      >
                        Address
                      </label>
                      <textarea
                        {...registerData('freightPayer.address')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="freightPayer.address"
                        rows="3"
                        placeholder="Enter freight payer address"
                      ></textarea>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="freightPayer.code"
                      >
                        Code
                      </label>
                      <input
                        {...registerData('freightPayer.code')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="freightPayer.code"
                        type="text"
                        placeholder="Freight Payer Code"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="freightPayer.reference"
                      >
                        Reference
                      </label>
                      <input
                        {...registerData('freightPayer.reference')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="freightPayer.reference"
                        type="text"
                        placeholder="Freight Payer Reference"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="freightPayer.contact"
                      >
                        Contact
                      </label>
                      <input
                        {...registerData('freightPayer.contact')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="freightPayer.contact"
                        type="text"
                        placeholder="Freight Payer Contact"
                      />
                    </div>

                    {/* Consignee Details */}

                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Consignee Details
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="consignee.name"
                      >
                        Name
                      </label>
                      <input
                        {...registerData('consignee.name')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="consignee.name"
                        type="text"
                        placeholder="Consignee Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="consignee.address"
                      >
                        Address
                      </label>
                      <textarea
                        {...registerData('consignee.address')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="consignee.address"
                        rows="3"
                        placeholder="Enter consignee address"
                      ></textarea>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="consignee.code"
                      >
                        Code
                      </label>
                      <input
                        {...registerData('consignee.code')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="consignee.code"
                        type="text"
                        placeholder="Consignee Code"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="consignee.reference"
                      >
                        Reference
                      </label>
                      <input
                        {...registerData('consignee.reference')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="consignee.reference"
                        type="text"
                        placeholder="Consignee Reference"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="consignee.contact"
                      >
                        Contact
                      </label>
                      <input
                        {...registerData('consignee.contact')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="consignee.contact"
                        type="text"
                        placeholder="Consignee Contact"
                      />
                    </div>

                    {/* Shipper Details */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Shipper Details
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="shipper.name"
                      >
                        Name
                      </label>
                      <input
                        {...registerData('shipper.name')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="shipper.name"
                        type="text"
                        placeholder="Shipper Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="shipper.address"
                      >
                        Address
                      </label>
                      <textarea
                        {...registerData('shipper.address')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="shipper.address"
                        rows="3"
                        placeholder="Enter shipper address"
                      ></textarea>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="shipper.code"
                      >
                        Code
                      </label>
                      <input
                        {...registerData('shipper.code')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="shipper.code"
                        type="text"
                        placeholder="Shipper Code"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="shipper.reference"
                      >
                        Reference
                      </label>
                      <input
                        {...registerData('shipper.reference')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="shipper.reference"
                        type="text"
                        placeholder="Shipper Reference"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="shipper.contact"
                      >
                        Contact
                      </label>
                      <input
                        {...registerData('shipper.contact')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="shipper.contact"
                        type="text"
                        placeholder="Shipper Contact"
                      />
                    </div>

                    {/* Voyage Details */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Voyage Details
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="voyageReference"
                      >
                        Voyage Reference
                      </label>
                      <input
                        {...registerData('voyageReference')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="voyageReference"
                        type="text"
                        placeholder="Voyage Reference"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="vesselName"
                      >
                        Vessel Name
                      </label>
                      <input
                        {...registerData('vesselName')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vesselName"
                        type="text"
                        placeholder="Vessel Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="exportMovementType"
                      >
                        Export Movement Type
                      </label>
                      <input
                        {...registerData('exportMovementType')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="exportMovementType"
                        type="text"
                        placeholder="Export Movement Type"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="importMovementType"
                      >
                        Import Movement Type
                      </label>
                      <input
                        {...registerData('importMovementType')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="importMovementType"
                        type="text"
                        placeholder="Import Movement Type"
                      />
                    </div>

                    {/* Place of Receipt */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Place of Receipt
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="placeOfReceipt.name"
                      >
                        Name
                      </label>
                      <input
                        {...registerData('placeOfReceipt.name')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="placeOfReceipt.name"
                        type="text"
                        placeholder="Place Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="placeOfReceipt.country"
                      >
                        Country
                      </label>
                      <input
                        {...registerData('placeOfReceipt.country')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="placeOfReceipt.country"
                        type="text"
                        placeholder="Country"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="placeOfReceipt.expectedDate"
                      >
                        Expected Date
                      </label>
                      <input
                        {...registerData('placeOfReceipt.expectedDate')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="placeOfReceipt.expectedDate"
                        type="date"
                        placeholder="Expected Date"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="placeOfReceipt.cargoReadyFrom"
                      >
                        Cargo Ready From
                      </label>
                      <input
                        {...registerData('placeOfReceipt.cargoReadyFrom')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="placeOfReceipt.cargoReadyFrom"
                        type="date"
                        placeholder="Cargo Ready From"
                      />
                    </div>

                    {/* Port of Loading */}

                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Port of Loading
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="portOfLoading.name"
                      >
                        Name
                      </label>
                      <input
                        {...registerData('portOfLoading.name')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfLoading.name"
                        type="text"
                        placeholder="Port Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="portOfLoading.unCode"
                      >
                        UN Code
                      </label>
                      <input
                        {...registerData('portOfLoading.unCode')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfLoading.unCode"
                        type="text"
                        placeholder="UN Code"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="portOfLoading.country"
                      >
                        Country
                      </label>
                      <input
                        {...registerData('portOfLoading.country')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfLoading.country"
                        type="text"
                        placeholder="Country"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="portOfLoading.expectedDate"
                      >
                        Expected Date
                      </label>
                      <input
                        {...registerData('portOfLoading.expectedDate')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfLoading.expectedDate"
                        type="date"
                        placeholder="Expected Date"
                      />
                    </div>

                    {/* Port of Discharge */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Port of Discharge
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="portOfDischarge.name"
                      >
                        Name
                      </label>
                      <input
                        {...registerData('portOfDischarge.name')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfDischarge.name"
                        type="text"
                        placeholder="Port Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="portOfDischarge.unCode"
                      >
                        UN Code
                      </label>
                      <input
                        {...registerData('portOfDischarge.unCode')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfDischarge.unCode"
                        type="text"
                        placeholder="UN Code"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="portOfDischarge.country"
                      >
                        Country
                      </label>
                      <input
                        {...registerData('portOfDischarge.country')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfDischarge.country"
                        type="text"
                        placeholder="Country"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="portOfDischarge.expectedDate"
                      >
                        Expected Date
                      </label>
                      <input
                        {...registerData('portOfDischarge.expectedDate')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfDischarge.expectedDate"
                        type="date"
                        placeholder="Expected Date"
                      />
                    </div>

                    {/* Place of Delivery */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Place of Delivery
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="placeOfDelivery.name"
                      >
                        Name
                      </label>
                      <input
                        {...registerData('placeOfDelivery.name')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="placeOfDelivery.name"
                        type="text"
                        placeholder="Place Name"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="placeOfDelivery.unCode"
                      >
                        UN Code
                      </label>
                      <input
                        {...registerData('placeOfDelivery.unCode')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="placeOfDelivery.unCode"
                        type="text"
                        placeholder="UN Code"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="placeOfDelivery.country"
                      >
                        Country
                      </label>
                      <input
                        {...registerData('placeOfDelivery.country')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="placeOfDelivery.country"
                        type="text"
                        placeholder="Country"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="placeOfDelivery.expectedDate"
                      >
                        Expected Date
                      </label>
                      <input
                        {...registerData('placeOfDelivery.expectedDate')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="placeOfDelivery.expectedDate"
                        type="date"
                        placeholder="Expected Date"
                      />
                    </div>

                    {/* Cargo Details */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Cargo Details
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="cargo.commodity"
                      >
                        Commodity
                      </label>
                      <input
                        {...registerData('cargo.commodity')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="cargo.commodity"
                        type="text"
                        placeholder="Commodity"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="cargo.totalNetWeight"
                      >
                        Total Net Weight
                      </label>
                      <input
                        {...registerData('cargo.totalNetWeight')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="cargo.totalNetWeight"
                        type="text"
                        placeholder="Total Net Weight (kg)"
                      />
                    </div>

                    {/* Container Details */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Container Details
                      </h3>

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="container.equipment"
                      >
                        Equipment
                      </label>
                      <input
                        {...registerData('container.equipment')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="container.equipment"
                        type="text"
                        placeholder="Equipment Type"
                      />

                      <label
                        className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                        htmlFor="container.totalCargoWeight"
                      >
                        Total Cargo Weight
                      </label>
                      <input
                        {...registerData('container.totalCargoWeight')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="container.totalCargoWeight"
                        type="text"
                        placeholder="Total Cargo Weight (kg)"
                      />

                      <div className="mt-2">
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            {...registerData('container.reefer')}
                            className="mr-2"
                          />
                          Reefer
                        </label>
                      </div>

                      <div className="mt-2">
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            {...registerData('container.hazardous')}
                            className="mr-2"
                          />
                          Hazardous
                        </label>
                      </div>

                      <div className="mt-2">
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            {...registerData('container.oog')}
                            className="mr-2"
                          />
                          OOG (Out of Gauge)
                        </label>
                      </div>

                      <div className="mt-2">
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            {...registerData('container.soc')}
                            className="mr-2"
                          />
                          SOC (Shipper-Owned Container)
                        </label>
                      </div>
                    </div>

                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Booking Status
                      </h3>

                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          {...registerData('isBooked')}
                          className="mr-2"
                        />
                        Booking Confirmed
                      </label>
                    </div>

                    {/* Submit and Cancel Buttons */}
                    <div className="flex justify-start mt-10">
                      <button
                        className={`bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 rounded mr-10 px-10 ${
                          loadingData && 'ActionButton'
                        }`}
                      >
                        {bookingDetails ? 'Update' : 'Add'}
                      </button>
                      <button
                        type="button"
                        onClick={closeModal}
                        className="text-blue-800 font-bold py-2 px-10 rounded border-blue-800 border-1"
                        disabled={loadingData}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="border-b-1 relative border-pos-settings"></div>

              <div className="pb-3 mt-6 bg-white">
                <div className="space-y-6">
                  <table className="w-full table-fixed">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="py-2 px-6 text-left text-sm">
                          BOOKING NUMBER
                        </th>
                        <th className="py-2 px-6 text-left"></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data?.map((b) => {
                          return (
                            <tr key={b?._id} className="bg-white rounded-lg">
                              <td className="py-2">{b?.bookingNumber}</td>
                              <td className="py-2"></td>
                              <td className="py-2">
                                <div className="flex flex-col sm:flex-row sm:justify-end">
                                  <button
                                    onClick={() => openModal(b?._id)}
                                    className="border border-gray-200 hover:bg-gray-200 text-gray-700 py-2 px-1 mb-2 sm:mb-0"
                                  >
                                    Update
                                  </button>

                                  <button
                                    onClick={() =>
                                      handleRemove(b?.bookingNumber, b?._id)
                                    }
                                    className="border border-gray-200  hover:bg-gray-200 text-gray-700 py-2 px-1 mb-2 sm:mb-0"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBooking;
